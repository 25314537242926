<template>
  <div class="login-input">
    <el-form ref="form" :model="ruleForm" :rules="rules">
      <el-form-item class="login-input-item" prop="phone">
        <phone-template @getPhone="getPhone"></phone-template>
      </el-form-item>
      <el-form-item class="login-input-item" prop="code">
        <code-template
          @codeVerify="codeVerify"
          @getCode="getCode"
          :phone="ruleForm.phone"
        ></code-template>
      </el-form-item>
    </el-form>
    <div class="login-input-item">
      <el-row justify="space-between">
        <el-col :span="12">
          <el-checkbox v-model="checked" class="login-checkbox"
            >自动登录</el-checkbox
          >
        </el-col>
      </el-row>
    </div>
    <div class="login-button login-input-item" @click="login">登&nbsp;录</div>
    <div class="other-login">
      <span class="other-login-txt">其他登录方式</span>
      <span class="wx-login-icon"></span>
    </div>
  </div>
</template>

<script>
import { CodeTemplate, PhoneTemplate } from "@/components/login/index";
import { codeLogin } from "@/api/user";
export default {
  data() {
    return {
      checked: true,
      ruleForm: {
        phone: "",
        code: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3,5,7,9,8]{1}\d{9}$/,
            message: "手机号格式有误",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码" }],
      },
    };
  },
  components: {
    PhoneTemplate,
    CodeTemplate,
  },
  methods: {
    getPhone(val) {
      this.ruleForm.phone = val;
    },
    getCode(val) {
      this.ruleForm.code = val;
    },
    codeVerify(callback) {
      this.$refs.form.validateField("phone", (result) => {
        if (!result) {
          callback();
        }
      });
    },
    login() {
      this.$refs.form.validate((bool, obj) => {
        if (bool) {
          const code = localStorage.getItem("code");
          if (!code) {
            this.$message({
              message: "验证码已失效，请重新获取",
              type: "error",
            });
            return;
          }
          if (this.ruleForm.code != Base64.decode(code)) {
            this.$message({
              message: "验证码输入有误",
              type: "error",
            });
            return;
          }
          const loading = this.$loading({
            lock: true,
            text: "登录中",
            spinner: "登录中",
            background: "rgba(0, 0, 0, 0.7)",
          });
          codeLogin({
            phone: this.ruleForm.phone,
            createTime: Base64.decode(localStorage.getItem("time")),
          })
            .then((res) => {
              loading.close();
              if (res && res.status == 200) {
                localStorage.removeItem("code");
                localStorage.removeItem("phone");
                localStorage.removeItem("time");
                localStorage.setItem("token", Base64.encode(res.data.token));
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify({
                    name: res.data.name,
                    avatar: res.data.avatar,
                  })
                );
                this.$router.replace("/index");
              }
            })
            .catch((errpr) => {
              loading.close();
            });
          setTimeout(() => {
            loading.close();
          }, 5000);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-input-item {
  margin-bottom: 24px;

  .login-checkbox {
    font-size: 14px;
    color: rgba(55, 69, 103, 0.85);
  }
}
.login-button {
  width: 100%;
  background: linear-gradient(to right, #00a0e9, #00cef6);
  color: #fff;
  padding: 8px 0;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 17px 42px -12px rgba(10, 169, 238, 0.3);
  border-radius: 2px;
}
.other-login {
  font-size: 14px;
  color: rgba(85, 97, 126, 1);
  .wx-login-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-left: 16px;
    background: url("../../../assets/images/login_wx_icon.png") center center;
    background-size: 100% 100%;
    vertical-align: middle;
  }
  .wx-login-icon:hover {
    background: url("../../../assets/images/login_wx.png") center center;
    background-size: 100% 100%;
  }
}
</style>